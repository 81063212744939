var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form-container',_vm._g(_vm._b({attrs:{"title":"Add/Edit Appeal","id":_vm.id,"provider":_vm.provider,"crud":_vm.crud,"item":_vm.item},on:{"update:item":function($event){_vm.item=$event}}},'form-container',Object.assign({}, _vm.$attrs, _vm.$props),false),_vm.$listeners),[(_vm.item)?_c('div',{},[_c('div',{staticClass:"row q-col-gutter-sm items-center"},[_c('div',{staticClass:"col-12"},[_c('date-picker',{attrs:{"filled":"","label":"Date","rules":[_vm.$rules.required]},model:{value:(_vm.item.date),callback:function ($$v) {_vm.$set(_vm.item, "date", $$v)},expression:"item.date"}})],1),_c('div',{staticClass:"col-12"},[_c('entity-select',{attrs:{"filled":"","value":_vm.item.applicantId,"provider":_vm.$service.providers.applicants,"label":"Applicant","rules":[_vm.$rules.required],"optionLabel":function (a) { return (a && a.id ? ("" + (a.name)) : ''); },"optionValue":function (a) { return (a ? a.id : null); },"oDataArgs":{
            columnFilters: [
              {
                column: 'OpenCallId',
                op: 'Equals',
                value: _vm.openCallId,
              } ],
          }},on:{"update:value":function($event){return _vm.$set(_vm.item, "applicantId", $event)}},model:{value:(_vm.item.applicant),callback:function ($$v) {_vm.$set(_vm.item, "applicant", $$v)},expression:"item.applicant"}})],1),_c('div',{staticClass:"col-12"},[_c('q-select',{attrs:{"filled":"","options":_vm.options,"label":"Type","rules":[_vm.$rules.required]},on:{"input":function($event){return _vm.bindSelectedType()}},model:{value:(_vm.selectedType),callback:function ($$v) {_vm.selectedType=$$v},expression:"selectedType"}})],1),_c('div',{staticClass:"col-12"},[(_vm.selectedType === 'Other')?_c('q-input',{attrs:{"filled":"","label":"Specify Type"},model:{value:(_vm.item.type),callback:function ($$v) {_vm.$set(_vm.item, "type", $$v)},expression:"item.type"}}):_vm._e()],1),_c('div',{staticClass:"col-12"},[_c('file-input',{attrs:{"filled":"","rules":[_vm.$rules.required],"label":"Document"},model:{value:(_vm.item.document),callback:function ($$v) {_vm.$set(_vm.item, "document", $$v)},expression:"item.document"}})],1),_c('div',{staticClass:"col-12"},[_c('q-select',{attrs:{"filled":"","options":['Pending', 'Approved', 'Rejected'],"label":"Decision","rules":[_vm.$rules.required]},model:{value:(_vm.item.appealDecision),callback:function ($$v) {_vm.$set(_vm.item, "appealDecision", $$v)},expression:"item.appealDecision"}})],1),_c('div',{staticClass:"col-12"},[_c('file-input',{attrs:{"filled":"","rules":_vm.item.appealDecision !== 'Pending' ? [_vm.$rules.required] : [],"label":"Decision Document"},model:{value:(_vm.item.decisionDocument),callback:function ($$v) {_vm.$set(_vm.item, "decisionDocument", $$v)},expression:"item.decisionDocument"}})],1)])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }