var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('model-table',_vm._b({attrs:{"provider":_vm.provider,"crud":_vm.crud,"columns":_vm.columns,"selection":'multiple',"filter":"openCallId eq ","allowExpand":true},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{staticClass:"row items-center"},[_c('div',{staticClass:"text-h6 q-pr-md"},[_vm._v("Applications")]),_c('q-btn',{attrs:{"icon":"download","label":"Extract","flat":"","no-caps":""},on:{"click":function($event){_vm.$runAsync('downloading', function () { return _vm.extract(); })}}})],1)]},proxy:true},{key:"row-actions",fn:function(ref){
var scope = ref.scope;
return [_c('q-btn',{attrs:{"icon":"visibility","flat":"","no-caps":""},on:{"click":function($event){_vm.item = scope.row;
          _vm.addApplicantForm = true;}}},[_c('q-tooltip',[_vm._v("Open")])],1),_c('q-btn',{attrs:{"dense":"","icon":"question_answer","flat":"","no-caps":""},on:{"click":function($event){_vm.isApplicant = true;
          _vm.item = scope.row;
          _vm.addEligibilityQuestionnaireSubmission = true;}}},[_c('q-tooltip',[_vm._v("Eligibility Questionnaire")])],1),(scope.row['partners@odata.count'] > 0)?_c('q-btn-dropdown',{attrs:{"dense":"","icon":"quiz","flat":"","no-caps":""}},[_c('q-list',[_c('q-item-label',{attrs:{"header":""}},[_vm._v("Partners")]),_c('q-separator'),_vm._l((scope.row.partners),function(p){return _c('q-item',{directives:[{name:"close-popup",rawName:"v-close-popup"}],key:p.id,attrs:{"clickable":""},on:{"click":function($event){_vm.item = p;
              _vm.isApplicant = false;
              _vm.addEligibilityQuestionnaireSubmission = true;}}},[_c('q-item-section',{attrs:{"avatar":""}},[_c('q-icon',{attrs:{"name":"question_answer"}})],1),_c('q-item-section',[_c('q-item-label',[_vm._v(_vm._s(p.name))]),_c('q-item-label',{attrs:{"caption":""}},[_vm._v(" Edit eligibility form")])],1)],1)})],2)],1):_vm._e(),_c('q-btn',{attrs:{"disable":!scope.row.eligibilityQuestionnaireSubmissionId,"icon":"download","dense":"","flat":"","no-caps":"","loading":_vm.$proxy['downloading' + scope.row.id]},on:{"click":function($event){_vm.$runAsync('downloading' + scope.row.id, function () { return _vm.download(scope.row.id); }
          )}}},[_c('q-tooltip',[_vm._v("Download Eligibility Form")])],1),_vm._t("row-buttons",null,null,{ scope: scope })]}},{key:"top-actions",fn:function(){return [_c('q-btn',{staticClass:"bg-green-5 text-white",attrs:{"icon":"add","label":"Add"},on:{"click":function($event){_vm.item = { id: 'new' };
          _vm.addApplicantForm = true;}}})]},proxy:true},{key:"expand-button-content",fn:function(ref){
          var scope = ref.scope;
return [_c('q-badge',{attrs:{"color":"orange","floating":"","transparent":"","label":scope.row['appeals@odata.count']}})]}},{key:"expanded",fn:function(ref){
          var scope = ref.scope;
return [_c('open-call-appeals',{attrs:{"applicantId":scope.row.id,"applicant":scope.row,"openCallId":_vm.openCallId,"viewPermission":function () { return true; },"deletePermission":function () { return true; }}})]}}],null,true)},'model-table',Object.assign({}, _vm.$props, _vm.$attrs),false)),_c('q-dialog',{attrs:{"persistent":""},model:{value:(_vm.addApplicantForm),callback:function ($$v) {_vm.addApplicantForm=$$v},expression:"addApplicantForm"}},[_c('applicant-form',{attrs:{"id":_vm.item.id,"openCallId":_vm.openCallId,"inDialog":true},on:{"save":function($event){return _vm.reset()}}})],1),_c('q-dialog',{attrs:{"persistent":""},model:{value:(_vm.addEligibilityQuestionnaireSubmission),callback:function ($$v) {_vm.addEligibilityQuestionnaireSubmission=$$v},expression:"addEligibilityQuestionnaireSubmission"}},[(_vm.item)?_c('eligibility-form',{attrs:{"isApplicant":_vm.isApplicant,"id":_vm.item.id,"openCallId":_vm.openCallId},on:{"save":function($event){_vm.addEligibilityQuestionnaireSubmission = false;
        _vm.reset();}}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }