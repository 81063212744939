




































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import ModelTable from "@/components/ModelTable.vue";
import { Location } from "vue-router";
import DataProvider from "@/lib/DataProvider";
import ICrudClient from "@/lib/ICrudClient";
import AppealForm from "@/components/forms/AppealForm.vue";
import columns from "@/data/columns/Appeals";
import { saveAs } from "file-saver";

@Component({
  components: {
    ModelTable,
    AppealForm
  }
})
export default class OpenCallAppeals extends Vue {
  @Prop()
  public openCallId!: string;

  @Prop()
  public applicantId!: string;

  @Prop()
  public applicant!: any;

  @Prop()
  public isTab!: boolean;

  public provider!: DataProvider<any>;

  public crud!: ICrudClient<any>;

  public columns: any[] = columns;

  private editDialog = false;
  private selectedItem = null;
  private openCallName!: string;

  @Prop()
  public addRoute!: (id: string) => Location;

  @Prop()
  public viewRoute!: (id: string) => Location;

  private selected: any[] = [];

  created() {
    this.initialize();
  }

  async mounted() {
    this.openCallName = (
      await this.$service.providers.openCalls.fetchItemAsync(this.openCallId)
    ).title;
  }
  initialize() {
    this.provider = this.$service.providers.openCallAppeals(
      this.openCallId,
      this.applicantId
    );
    this.crud = this.$service.crud.appeals;
  }
  async extract() {
    const utc = new Date().toJSON().slice(0, 10);
    saveAs(
      await this.$service.extract(
        "openCalls/appeals",
        null,
        this.openCallId
      ),
      `Appeals_${this.openCallName}_${utc}.xlsx`
    );
  }

  private navigateToRoute(route: (id: string) => Location, id: any) {
    if (!route) {
      return;
    }
    this.$router.push(route(id));
  }
}
