


























































































import ICrudClient from "@/lib/ICrudClient";
import DataProvider from "@/lib/DataProvider";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import FormContainer from "../FormContainer.vue";
import DatePicker from "../DatePicker.vue";
import Loader from "../Loader.vue";
import EntitySelect from "@/components/EntitySelect.vue";
import FileInput from "@/components/FileInput.vue";
import { warn } from "vue-class-component/lib/util";

@Component({
  components: {
    FormContainer,
    Loader,
    DatePicker,
    EntitySelect,
    FileInput,
  },
})
export default class AppealForm extends Vue {
  @Prop()
  public id!: string;

  @Prop()
  public openCallId!: string;

  @Prop()
  public applicantId!: string;

  @Prop()
  public applicant!: any;

  public provider!: DataProvider<any>;

  public crud!: ICrudClient<any>;

  private item: any = null;
  private selectedType: any = null;
  private options: any = ['Eligibility', 'Scoring', 'Selection', 'Other'];

  created() {
    this.initialize();
  }
  initialize() {
    this.provider = this.$service.providers.appeals;
    this.crud = this.$service.crud.appeals;
  }
  async mounted() {
    if (this.id === "new") {
      this.item = {
        openCallId: this.openCallId,
        applicant: this.applicant,
        applicantId: this.applicantId,
        date: null,
        appealDecision: "Pending",
        document: null,
        decisionDocument: null,
        type: this.options[0]
      };
    }
  }
  
  @Watch('item')
  onItemChange() {
    if (this.item)
      this.getOption();
  }

  private bindSelectedType() {
    if (this.selectedType !== 'Other')
      this.item.type = this.selectedType;
  }

  private getOption() {
    if(this.item.type && this.options.includes(this.item.type))
      this.selectedType = this.item.type
    else
      this.selectedType = 'Other';
  }
}
