export default [
  {
    name: "Number",
    required: true,
    label: "S/N",
    align: "left",
    sortable: true,
    field: (i: any) => i.number
  },
  {
    name: "name",
    required: true,
    label: "Name",
    align: "left",
    sortable: true,
    field: (i: any) => i.name
  },
  {
    name: "eligibilityOutcome",
    required: true,
    label: "Eligibility Outcome",
    align: "left",
    sortable: true,
    field: (i: any) => i.eligibilityOutcome
  },
  {
    name: "submissionDate",
    required: true,
    label: "Submission Date",
    align: "left",
    sortable: true,
    field: (i: any) => {
      if (i.submissionDate) {
        return new Date(Date.parse(i.submissionDate));
      }
      return undefined;
    },
    format: (val: Date) => (val ? val.toLocaleDateString() : "")
  }
];
