
















































































































































































































































import ICrudClient from "@/lib/ICrudClient";
import DataProvider from "@/lib/DataProvider";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import FormContainer from "../FormContainer.vue";
import Loader from "../Loader.vue";
import FileInput from "@/components/FileInput.vue";
import { ActionType } from "@/data/models/ActionTypes";
import EntitySelect from "@/components/EntitySelect.vue";
import DatePicker from "@/components/DatePicker.vue";
import DynamicForm from "@/components/DynamicForm.vue";
import SubArrayForm from "@/components/SubArrayForm.vue";
import { Criterion } from "@/data/models/OpenCall";

@Component({
  components: {
    FormContainer,
    Loader,
    FileInput,
    EntitySelect,
    DatePicker,
    DynamicForm,
    SubArrayForm
  }
})
export default class CriterionForm extends Vue {
  @Prop()
  public crud!: ICrudClient<Criterion>;

  @Prop()
  public id!: string;

  @Prop()
  public openCallId!: string;

  private eligibilityOutcomes = ["Pending", "Passed", "Failed"];

  public item: any = null;

  @Watch("item")
  onItemChanged() {
    //
  }

  @Watch("id", { immediate: true })
  onIdChanged() {
    if (this.id === "new") {
      this.item = {
        openCallId: this.openCallId,
        submissionDate: new Date().toISOString(),
        name: null,
        projectName: null,
        eligibilityOutcome: null,
        submissionFile: null,
        decisionFile: null,
        legalRepresentative: null,
        totalProjectBudget: null,
        totalRequested: null,
        leadBudget: null,
        leadFunding: null,
        partners: [],
        proposedStartDate: null,
        proposedEndDate: null,
        comments: "",
        phone: null,
        email: null
      };
    }
  }
  async submit() {
    //
  }
}
