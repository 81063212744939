






















































































































































import ICrudClient from "@/lib/ICrudClient";
import DataProvider from "@/lib/DataProvider";
import { Component, Prop, Vue } from "vue-property-decorator";
import FormContainer from "@/components/FormContainer.vue";
import draggable from "vuedraggable";
import { Project } from "@/data/models/Projects";
import { Guid } from "guid-typescript";
import DatePicker from "@/components/DatePicker.vue";
import EligibilityForm from "@/components/forms/EligibilityForm.vue";
import FileInput from "@/components/FileInput.vue";
import ApplicantColumns from "@/data/columns/Applicants";
import ModelTable from "@/components/ModelTable.vue";
import ApplicantForm from "@/components/forms/ApplicantForm.vue";
import { Applicant } from "@/data/models/OpenCall";
import DynamicForm from "@/components/DynamicForm.vue";
import OpenCallAppeals from "@/views/OpenCallAppeals.vue";
import { saveAs } from "file-saver";
@Component({
  components: {
    FormContainer,
    DatePicker,
    FileInput,
    DynamicForm,
    ModelTable,
    ApplicantForm,
    EligibilityForm,
    draggable,
    OpenCallAppeals
  }
})
export default class Applicants extends Vue {
  private provider: DataProvider<Applicant> | null = null;

  @Prop()
  public crud!: ICrudClient<Applicant>;

  @Prop()
  public id!: string;

  private openCallName!: string;

  @Prop()
  public openCallId!: string;

  private columns = ApplicantColumns;

  private addApplicantForm = false;
  private isApplicant = true;
  private addEligibilityQuestionnaireSubmission = false;
  private eligibilityQuestionnaire: any = null;
  private eligibilityQuestionnaireAnswers: any[] | null = null;
  private utc = new Date().toJSON().slice(0, 10);

  private item = {
    openCallId: this.openCallId,
    submissionDate: new Date().toISOString(),
    activeScorers: [],
    comments: ""
  };

  reset() {
    this.item = {
      openCallId: this.openCallId,
      submissionDate: new Date().toISOString(),
      activeScorers: [],
      comments: ""
    };
    this.addApplicantForm = false;
    this.provider = this.$service.providers.openCallApplicant(this.openCallId);
  }

  async mounted() {
    this.openCallName = (
      await this.$service.providers.openCalls.fetchItemAsync(this.openCallId)
    ).title;
    this.provider = this.$service.providers.openCallApplicant(this.openCallId);
  }

  async download(applicantId: string) {
    saveAs(
      await this.$service.downloadEligibilityForm(applicantId),
      `EligibilityForm_${this.openCallName}_${this.utc}.pdf`
    );
  }

  async extract() {
    saveAs(
      await this.$service.extract(
        "openCalls/applications",
        null,
        this.openCallId
      ),
      `Applications_${this.openCallName}_${this.utc}.xlsx`
    );
  }
}
