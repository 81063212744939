export default [
  {
    name: "date",
    required: true,
    label: "Date",
    align: "left",
    sortable: true,
    field: (i: any) => (i.date ? new Date(Date.parse(i.date)) : undefined),
    format: (val: Date) => (val ? val.toLocaleDateString() : "")
  },
  {
    name: "Applicant/Name",
    required: true,
    label: "Applicant",
    align: "left",
    sortable: true,
    field: (i: any) => `${i.applicant.name}`
  },
  {
    name: "Type",
    required: true,
    label: "Type",
    align: "left",
    sortable: true,
    field: (i: any) => `${i.type}`
  },
  {
    name: "AppealDecision",
    required: true,
    label: "Decision",
    align: "left",
    sortable: true,
    field: (i: any) => i.appealDecision
  }
];
