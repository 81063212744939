

























import { Component, Prop, Vue } from "vue-property-decorator";
import FormContainer from "../FormContainer.vue";
import Loader from "../Loader.vue";
import DynamicForm from "@/components/DynamicForm.vue";
import {
  NewQuestionnaireSubmission,
  Questionnaire,
  QuestionnaireSubmission
} from "@/data/models/Questionnaires";
import { User } from "@/data/models/Users";
import { Guid } from "guid-typescript";
@Component({
  components: {
    FormContainer,
    Loader,
    DynamicForm
  }
})
export default class EligibilityForm extends Vue {
  @Prop()
  public openCallId!: string;

  @Prop()
  public id!: string;

  @Prop()
  public isApplicant!: boolean;

  public applicant!: any;

  private users: any[] = [];

  private questionnaireId: any = null;

  private eligibilityQuestionnaire: any = null;
  private eligibilityQuestionnaireAnswers: any[] = [];

  async submit() {
    const qSub = NewQuestionnaireSubmission(
      this.questionnaireId,
      this.eligibilityQuestionnaireAnswers || []
    );
    const itemToSave = JSON.parse(JSON.stringify(this.applicant));
    itemToSave.eligibilityQuestionnaireSubmission = qSub;
    if (this.isApplicant) {
      await this.$service.crud.applicants.saveAsync(itemToSave);
    } else {
      await this.$service.crud.partners.saveAsync(itemToSave);
    }
  }
  async loadSubmission(q: any) {
    const sub = await this.$service.providers.questionnaireSubmissions.fetchItemAsync(
      q.id
    );
    this.eligibilityQuestionnaire = sub.questionnaire;
    this.eligibilityQuestionnaireAnswers = sub.answers;
  }
  async initialize() {
    if (this.isApplicant) {
      this.applicant = await this.$service.providers.applicants.fetchItemAsync(
        this.id
      );
    } else {
      this.applicant = await this.$service.providers.partners.fetchItemAsync(
        this.id
      );
    }
    if (this.applicant && this.applicant.eligibilityQuestionnaireSubmission) {
      await this.loadSubmission(
        this.applicant.eligibilityQuestionnaireSubmission
      );
    }
    this.users = (await this.$service.providers.users.fetchItemsAsync()).items;

    this.questionnaireId = (
      await this.$service.providers.openCalls.fetchItemAsync(this.openCallId)
    ).eligibilityQuestionnaireId;

    this.eligibilityQuestionnaire = await this.$service.providers.questionnaires.fetchItemAsync(
      this.questionnaireId
    );
  }
  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
}
